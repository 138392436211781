import { http } from '@/http/axios.js'
// 介绍
export function getPointsIntroAPI() {
  return http({
    url: '/company/basic/integral/getIntegralIntro',
    method: 'get'
  })
}
// 编辑介绍
export function editPointsIntroAPI(data) {
  return http({
    url: '/company/basic/integral/settingIntegralIntro',
    method: 'post',
    data
  })
}
// 配置列表
export function getListAPI(params) {
  return http({
    url: '/company/basic/integral/list',
    method: 'get',
    params
  })
}
// 配置详情
export function getPointsDetailAPI(id) {
  return http({
    url: '/company/basic/integral/detail',
    method: 'get',
    params: { id }
  })
}
// 编辑配置
export function eidtPointsAPI(data) {
  return http({
    url: '/company/basic/integral/edit',
    method: 'post',
    data
  })
}
