<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="80px">
      <!-- <el-form-item label="规则标题">
        <el-input v-model="formData.name" size="small"></el-input>
      </el-form-item> -->
      <el-form-item label="规则描述" prop="desc" :rules="rules">
        <el-input type="textarea" :rows="4" v-model="formData.desc" size="small"></el-input>
      </el-form-item>
      <el-form-item label="规则积分" prop="integral" :rules="rules">
        <el-input v-model.number="formData.integral" size="small"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status" :rules="rules">
        <el-radio-group v-model="formData.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getPointsDetailAPI, eidtPointsAPI } from './api'
export default {
  name: 'AddOrEdit',

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        desc: '登录的得到的积分',
        integral: 5,
        status: 0
      }
    }
  },

  mounted() {
    this.getPointsDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          eidtPointsAPI(this.formData).then(() => {
            this.$message.success('配置成功')
            this.$router.back()
          })
        }
      })
    },
    async getPointsDetail() {
      this.formData = await getPointsDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form {
    .el-form-item {
      .el-input,
      .el-textarea {
        width: 400px;
      }
    }
  }
}
</style>
